import React from "react"
import styled from "styled-components"
import TechStackMarquee from "./techstackmarquee"
import TechStackDetail from "./techstackdetail"

const TechCardContainer = styled.div`
  background-color: transparent;
  border-radius: 0.8rem;
  position: relative;
  display: flex;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  align-items: center;
  justify-content: start;
  margin-bottom: 3rem;
`
// margin-left added for full width marquee scrolling

const TechStackCard = inputProps => {
  return (
    <TechCardContainer>
      <TechStackMarquee data={inputProps.data} />
      <TechStackDetail data={inputProps.data} />
    </TechCardContainer>
  )
}
export default TechStackCard
