import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionHeader } from "../utils/commonComponents"
import { techStack } from "../data/portfolio"
import TechStackCard from "../components/techstackcard"

const SkillsPage = () => (
  <Layout>
    <SEO title="skills" />
    <SectionHeader header="TECHNICAL SKILLS" />
    {techStack.map((stack, idx) => (
      <div
        data-sal={idx % 2 === 0 ? "slide-right" : "slide-left"}
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
        key={String(stack.name) + String(idx)}
      >
        <TechStackCard data={stack} key={String(stack.name) + String(idx)} />
      </div>
    ))}
  </Layout>
)

export default SkillsPage
