import React from "react"
import styled from "styled-components"
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider"
import sizeMe from "react-sizeme"

const MarqueeContainer = styled.main`
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: transparent;
  padding: ${props => props.scale * 3};
  position: relative;
`
const Logo = styled.img`
  width: ${props => props.scale * 170}px;
  height: ${props => props.scale * 170}px;
  margin: -15px;
  vertical-align: middle;
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  object-fit: scale-down;
  object-position: bottom;
`

const TechStackMarquee = inputProps => {
  const { width } = inputProps.size
  let scale = 0.5
  if (width && width > 800) {
    scale = 0.75
  }

  if (width && width > 920) {
    scale = 1
  }
  return (
    <MarqueeContainer>
      <Marquee velocity={10} minScale={0.5} resetAfterTries={200}>
        {inputProps.data.map((technology, idx) =>
          idx !== 0 ? (
            <Motion
              key={`child-${technology}`}
              initDeg={randomIntFromInterval(0, 180)}
              direction={"ltr"}
              velocity={7}
              radius={scale * 100}
            >
              <Logo
                src={`/${technology.image}`}
                alt="technology icon"
                scale={scale}
              />
            </Motion>
          ) : null
        )}
      </Marquee>
    </MarqueeContainer>
  )
}

export default sizeMe()(TechStackMarquee)
