import React from "react"
import styled from "styled-components"

const CardContainer = styled.div`
  width: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  font-size: 0.5rem;
  left: 50%;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  font-family: sans-serif;
  border-radius: 0.8em;
  height: 80%;
  overflow: hidden;
  transition: box-shadow 0.2s ease;
  :hover {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);
  }
`
const CardDetails = styled.div`
  padding: 0.75rem;
  line-height: 11px;
  @media (max-width: 768px) {
    line-height: 4px;
  }
`
const CardHeader = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 800;
  display: inline-block;
  margin-top: 0.55em;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 468px) {
    font-size: 14px;
  }
`
const CardList = styled.ul`
  column-count: 3;
  margin-left: 0px;
  @media (max-width: 468px) {
    column-count: 2;
  }
`
const CardListItem = styled.li`
  font-size: 14px;
  list-style-type: none;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 468px) {
    font-size: 10px;
  }
`

const TechStackDetail = inputProps => {
  const { data } = inputProps
  return (
    <CardContainer>
      <CardDetails>
        <CardHeader>{data[0].name}</CardHeader>
        <CardList>
          {data.map((technology, idx) =>
            idx !== 0 ? (
              <CardListItem key={technology.name}>
                {technology.name}
              </CardListItem>
            ) : null
          )}
        </CardList>
      </CardDetails>
    </CardContainer>
  )
}

export default TechStackDetail
